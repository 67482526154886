/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { callAPI } from 'helpers';
import cn from 'classnames';
import _ from 'lodash';

// Components
import ModalExamConsent from 'components/profile/exam-applications/ModalExamConsent';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Data
import { TGATTPATINFOS, ALEVELINFOS } from 'data/exam-days';

const ExamApplicationInfo = ({ type = 'tgattpat' }) => {
  const infos = type === 'tgattpat' ? TGATTPATINFOS : ALEVELINFOS;
  const subjectMap = useMemo(
    () =>
      infos.reduce((_map, { date, dayIndex, examSlots }) => {
        return _.merge(
          _map,
          _.keyBy(
            examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
            'subjectKey'
          )
        );
      }, {}),
    [infos]
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [ppType, setPpType] = useState();

  const consentAccepted = _.get(profile, 'consents_accepted_at.exam_application_2568');
  const [modalVisible, setModalVisible] = useState(!consentAccepted);

  useEffect(() => setModalVisible(!consentAccepted), [consentAccepted]);
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, pp_type } = await callAPI({ url: '/applicants/me/exam-applications', query: { type } });
        setPpType(pp_type);
        setApplications(
          applications
            .sort((a, b) => {
              const subjectA = subjectMap[a.subject_name] || {};
              const subjectB = subjectMap[b.subject_name] || {};
              return subjectA.dayIndex - subjectB.dayIndex || subjectA.slotIndex - subjectB.slotIndex;
            })
            .slice()
        );
        if (!applications.length) {
          navigate('/profile');
        }
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    fetchApplications();
  }, [navigate, type, subjectMap]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setModalVisible(false);
  };

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  useEffect(() => {
    if (profile && (!profile.picture || !profile.program_code)) {
      navigate('/profile');
    }
  }, [profile, navigate]);

  const [opening, setOpening] = useState(false);
  const onPdfOpen = async () => {
    if (opening || !profile.picture) return;
    try {
      setOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: type === 'tgattpat' ? '/exam-applications/tgattpat-seat-pdf' : '/exam-applications/alevel-seat-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setTimeout(() => setOpening(false), 1000);
    } catch (error) {
      console.error(`Error while trying to download summary pdf: ${error.message}`);
      setOpening(false);
    }
  };

  const cbtTestInfo = _.find(applications, ({ selected_exam_type }) => selected_exam_type === 'cbt');
  return (
    <main className="site-body">
      <div className="t-form">
        {loading && <div className="t-loading" />}
        <h1>
          {t('ข้อมูลการสมัครสอบ')} {type === 'alevel' ? 'A-Level' : 'TGAT/TPAT2-5'}
        </h1>
        {(ppType === '1') || Boolean(profile.tgattpat_postpone_confirmed_at) ? (
          <div className={cn('t-box -border  text-center')} style={{ borderColor: '#ff6600' }}>
            <h4 className="color-orange flex items-center gap-2 justify-center mb-2">
              <i className="i-postpone i-20"></i>
              {t('อัปเดตข้อมูลที่นั่งสอบและสนามสอบเรียบร้อยแล้ว')}
            </h4>
            <p>กรุณาตรวจสอบ <b>"รายละเอียดการสอบ"</b></p>
            {ppType !== '1' && (
              <h4 className="color-orange flex items-center gap-2 justify-center mt-1 mb-2" style={{ flexDirection: 'column' }}>
                <div>*อนุญาตให้เข้าสอบได้เฉพาะผู้ที่ลงทะเบียนขอเลื่อนสอบ</div>
                <div>ในระบบมาล่วงหน้า และยังไม่ได้เข้าสอบในช่วง</div>
                <div>วันที่ 7-9 ธันวาคม 2567 ที่ผ่านมา<u>เท่านั้น</u></div>
              </h4>
            )}
            {/* <h4 className="color-orange flex items-center gap-2 justify-center mt-1 mb-2">
              <i className="i-alert i-20" /> เลื่อนวันสอบ TGAT/TPAT2-5
            </h4>
            <p>
              เลื่อนวันสอบเป็นวันที่ <strong>21 - 23 ธันวาคม 2567</strong>
              <br />
              ตามประกาศชี้แจงการจัดสอบ TGAT/TPAT2-5
            </p>
            <a
              rel="noreferrer"
              target="_blank"
              className="color-orange cursor-pointer _heading"
              href="https://assets.mytcas.com/68/announcement/tgat-tpat2-5-postpone.pdf"
            >
              <strong>{t('รายละเอียดประกาศ')} →</strong>
            </a>
            <br />
            <small className="color-orange mb-0 _heading">
              <strong>หมายเหตุ: ข้อมูลที่นั่งสอบและสนามสอบใหม่จะอัปเดตอีกครั้งหลังวันที่ 5 ธันวาคม 2567</strong>
            </small> */}
          </div>
        ) : null}

        {Boolean(cbtTestInfo) && (
          <div className={cn('t-box -border -noti', { hide: false })}>
            <h2>ทดลองสอบด้วยเครื่องคอมพิวเตอร์</h2>
            <ul className="list-none">
              <li>
                {t('ชื่อ')}{' '}
                <b>
                  {_.get(profile, 'first_name')} {_.get(profile, 'last_name')}
                </b>
              </li>
              <li>
                {t('รหัสประจำตัวสอบ')} <b>{cbtTestInfo.app_id}</b>
              </li>
              <li>
                {t('เลขบัตรประชาชน')} <b>{cbtTestInfo.citizen_id}</b>
              </li>
              {cbtTestInfo.test_system_site_name ? (
                <>
                  <li>
                    {t('วันทดลองสอบ')} <b>{cbtTestInfo.test_system_date}</b>
                  </li>
                  <li>
                    {t('เวลา')} <b>{cbtTestInfo.test_system_time} น.</b>
                  </li>
                  <li>
                    {t('สนามสอบ')} <b>{cbtTestInfo.test_system_site_name}</b>
                  </li>
                  <li>
                    {t('อาคาร')}{' '}
                    <b>
                      {(cbtTestInfo.test_system_building || '').startsWith('อาคาร')
                        ? cbtTestInfo.test_system_building.replace('อาคาร', '')
                        : cbtTestInfo.test_system_building}
                    </b>{' '}
                    {t('ห้อง')}{' '}
                    <b>
                      {(cbtTestInfo.test_system_room || '').startsWith('ห้อง')
                        ? cbtTestInfo.test_system_room.replace('ห้อง', '')
                        : cbtTestInfo.test_system_room}
                    </b>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    สามารถทดลองได้ที่เว็บไซต์​{' '}
                    <a target="_blank" href="https://cbt.mytcas.com">
                      https://cbt.mytcas.com
                    </a>
                  </li>
                </>
              )}
            </ul>
            {Boolean(cbtTestInfo.test_system_site_name) && (
              <h4 className="color-orange mb-0">
                <b>หมายเหตุ:</b> กรุณามาถึงสนามทดลองสอบอย่างน้อย 15 นาทีก่อนเวลานัดหมาย
              </h4>
            )}
          </div>
        )}
        <div className="xm-info">
          <h2>{t('รายละเอียดการสอบ')}</h2>
          <div className="list">
            {!profile.picture && (
              <div className="xm-error">
                <div className="icon">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.425 39.15H37.5375C38.925 39.15 40.2 38.4375 40.875 37.2C41.55 36 41.55 34.5375 40.8375 33.3375L27.3 10.725C26.5875 9.56248 25.3875 8.84998 24 8.84998C22.6125 8.84998 21.4125 9.56248 20.7 10.725L7.1625 33.3375C6.45 34.5375 6.4125 36 7.125 37.2C7.8 38.4 9.0375 39.15 10.425 39.15ZM9.075 34.5L22.65 11.8875C22.95 11.4 23.4375 11.1 24 11.1C24.5625 11.1 25.05 11.3625 25.35 11.8875L38.925 34.5C39.225 34.9875 39.225 35.5875 38.9625 36.1125C38.6625 36.6 38.175 36.9 37.575 36.9H10.425C9.8625 36.9 9.3375 36.6 9.0375 36.1125C8.7375 35.625 8.775 34.9875 9.075 34.5Z"
                      fill="white"
                    />
                    <path
                      d="M24 28.6124C24.6375 28.6124 25.125 28.1249 25.125 27.4874V19.6124C25.125 18.9749 24.6375 18.4874 24 18.4874C23.3625 18.4874 22.875 18.9749 22.875 19.6124V27.4874C22.875 28.1249 23.3625 28.6124 24 28.6124ZM24 30.1874C23.3775 30.1874 22.875 30.6899 22.875 31.3124C22.875 31.9349 23.3775 32.4374 24 32.4374C24.6225 32.4374 25.125 31.9349 25.125 31.3124C25.125 30.6899 24.6225 30.1874 24 30.1874Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="text">
                  <h2>{t('ไม่พบรูปถ่ายติดบัตรของท่าน')}</h2>
                  <span>
                    {t('กรุณาอัปโหลดรูปถ่ายแนวตั้ง เพื่อใช้ในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}{' '}
                    {t(
                      'โดยใช้รูปถ่ายปัจจุบันหน้าตรง ชัดเจน ครึ่งตัวในชุดสุภาพ เพื่อเป็นหลักฐานสำหรับตรวจสอบก่อนเข้าห้องสอบ'
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className="sub">
              <h2>
                {_.get(profile, 'title')}
                {_.get(profile, 'first_name')} {_.get(profile, 'last_name')}
              </h2>
              <h3 className="_space">
                {t('สมัครสอบจำนวน')}{' '}
                <b>
                  {(applications || []).length} {t('วิชา')}
                </b>
              </h3>
              <div className="action _space">
                <a
                  className={cn('btn-main', {
                    'cursor-pointer': !opening,
                    disabled: opening || !profile.picture,
                    loading: opening,
                  })}
                  onClick={onPdfOpen}
                >
                  {t('พิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
                </a>
              </div>
            </div>
            {(applications || []).map((app, index) => {
              let site = app.site_info || {};
              let subject = subjectMap[app.subject_name] || {};

              let schoolName = site.school_name;
              let buildingName = app.building_name;
              let floor = app.floor;
              let roomName = app.room_name;
              let amphur = (site.amphur || '').startsWith('เขต') ? site.amphur : `อำเภอ${site.amphur || ''}`;
              let province = site.province;

              if (ppType === '1') {
                subject.date = subject.date
                  .replace('7 ธ.ค.', '21 ธ.ค.')
                  .replace('8 ธ.ค.', '22 ธ.ค.')
                  .replace('9 ธ.ค.', '23 ธ.ค.');
              } else if (profile.tgattpat_postpone_confirmed_at) {
                subject.date = subject.date
                  .replace('7 ธ.ค.', '21 ธ.ค.')
                  .replace('8 ธ.ค.', '22 ธ.ค.')
                  .replace('9 ธ.ค.', '23 ธ.ค.');
                // schoolName = '-รอระบุ-';
                // buildingName = '-';
                // floor = '-';
                // roomName = '-';
                // amphur = '-';
                // province = '';
              }

              return (
                <div key={app._id} className={'xm-box -s' + subject.subjectCode}>
                  <div className="num">
                    <b>{index + 1}</b>
                  </div>
                  <ul>
                    <li>
                      {t('เลขที่นั่งสอบ')} <b>{app.app_id}</b>
                    </li>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subject.subjectKey} {subject.subjectName}
                      </b>
                    </li>
                    <li>
                      {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                    </li>
                    <li>
                      {t('วันที่สอบ')} <b>{subject.date}</b>&nbsp;
                      <br className="_mobile" /> เวลา <b>{subject.time}</b>
                    </li>
                    <li>
                      {t('สนามสอบ')} <b>{schoolName}</b>
                    </li>
                    <li>
                      {t('อาคาร')} <b>{buildingName}</b> &nbsp;&nbsp; {t('ชั้น')} <b>{floor}</b> {t('ห้อง')}{' '}
                      <b>{roomName}</b>
                    </li>
                    {/* <li>
                      {t('ตำแหน่งที่นั่ง')}{' '}
                      <b>
                        แถว <small>(Row)</small> {app.row} • ตอน <small>(Column)</small> {app.col}
                      </b>
                    </li> */}
                    <li>
                      {t('ที่ตั้งสนามสอบ')}{' '}
                      <b>
                        {amphur}{' '}{province}
                      </b>
                    </li>
                    <li className="flex gap-1 items-center">
                      {t('ประเภทข้อสอบ')}
                      <b className="inline-flex gap-1 items-center">
                        {app.selected_exam_type === 'cbt' ? (
                          <>
                            <i className="i-computer" />
                            <b>{t('คอมพิวเตอร์')}</b>
                          </>
                        ) : app.selected_exam_type === 'pbt' ? (
                          <>
                            <i className="i-paper" />
                            <b>{t('กระดาษ')}</b>
                          </>
                        ) : null}
                      </b>
                    </li>
                    {Boolean(app.special_request) && (
                      <li className="color-orange">
                        <img
                          style={{ verticalAlign: 'top', marginTop: 2 }}
                          src="/assets/img/i/i-disability-orange.svg"
                          alt="disability"
                          width="20"
                          height="20"
                        />
                        <b>{app.special_request}</b>
                      </li>
                    )}
                  </ul>
                </div>
              );
            })}
            <div className="action">
              <a
                className={cn('btn-main', {
                  'cursor-pointer': !opening,
                  disabled: opening || !profile.picture,
                  loading: opening,
                })}
                onClick={onPdfOpen}
              >
                {t('พิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
              </a>
            </div>
            {/* )} */}
          </div>
        </div>

        <h4 className="text-center">
          <Link to="/profile">{t('กลับสู่หน้าข้อมูลการสมัครสอบ')} →</Link>
        </h4>
      </div>
      <ModalExamConsent visible={modalVisible} onSubmit={() => _onAcceptConsent('exam_application_2568')} />
    </main>
  );
};

export default ExamApplicationInfo;
