/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { callAPI } from 'helpers';
import cn from 'classnames';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Data
import { TGATTPATINFOS, ALEVELINFOS } from 'data/exam-days';
import {
  // cbt_tgattpat_recheck,
  pbt_tgattpat_recheck,
  // pbt_alevel_recheck
}  from 'data/enabled';
import moment from 'moment';

const CBT = false;
const ExamRecheck = ({ type = 'tgattpat' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const paidCount = applications.filter((app) => app.recheckable).length;

  const subjectInfos = type === 'tgattpat' ? TGATTPATINFOS : ALEVELINFOS;
  const subjectMap = subjectInfos.reduce((_map, { date, dayIndex, examSlots }) => {
    return _.merge(
      _map,
      _.keyBy(
        examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
        'subjectKey'
      )
    );
  }, {});

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, timestamp } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type }
        });

        // const availableTime = moment(timestamp).isBetween(cbt_tgattpat_recheck.payment_start, cbt_tgattpat_recheck.payment_end);
        const availableTime = moment(timestamp).isBetween(pbt_tgattpat_recheck.payment_start, pbt_tgattpat_recheck.payment_end);
        // const availableTime = type === 'tgattpat'
        //   ? moment(timestamp).isBetween(pbt_tgattpat_recheck.payment_start, pbt_tgattpat_recheck.payment_end)
        //   : moment(timestamp).isBetween(pbt_alevel_recheck.payment_start, pbt_alevel_recheck.payment_end);
        if (!applications.length || !availableTime) {
          navigate('/profile');
        }
        const _applications = applications
          .filter((_app) => !(_app.is_absent || _app.is_dishonest || _app.is_not_announced))
          .sort((a, b) => a.subject_name.localeCompare(b.subject_name))
          // .filter(app => app.selected_exam_type === 'cbt')
          .slice();

        if (_applications.length && _applications.some((_app) => !_app.recheckable)) {
          setApplications(_applications);
          setLoading(false);
        } else {
          navigate('/profile');
        }
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    const consentAccepted = _.get(profile, `consents_accepted_at.${type}_exam_recheck_2568`);
    if (consentAccepted) {
      fetchApplications();
    } else {
      navigate(`/profile/exam-recheck-consent/${type}`);
    }
  }, [navigate, profile, type]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const [selectedAppIds, setSelectedAppIds] = useState([]);

  const onSubjectToggle = (appId, selected) => {
    if (selected) {
      if (selectedAppIds.length > 2 - paidCount) {
        setSelectedAppIds([]);
      } else {
        setSelectedAppIds(selectedAppIds.filter((id) => id !== appId));
      }
    } else {
      if (selectedAppIds.length === 2 - paidCount) {
        setSelectedAppIds(applications.filter((app) => !app.recheckable).map((app) => app._id));
      } else {
        setSelectedAppIds((ids) => ids.concat([appId]));
      }
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/exam-recheck/invoice-pdf',
        body: {
          selectedAppIds,
          type
        },
      });
      window.location.href = pdf;
      setTimeout(() => setSubmitting(false), 3000);
    } catch (error) {
      console.error(new Error(`Error while trying to submit exam recheck form: ${error.message}`));
      setSubmitting(false);
    }
  };

  const disabled = !selectedAppIds.length;

  const paidAmount =
    paidCount >= 3 ? 0 : selectedAppIds.length < 3 - paidCount ? 100 * selectedAppIds.length : (3 - paidCount) * 100;
  const title = t(`ขอทบทวนผลคะแนนสอบ${paidCount ? 'เพิ่ม' : ''}`);
  return (
    <main className="site-body">
      <div className="t-form">
        {loading && <div className="t-loading" />}
        <h1>{title}</h1>
        <div className="xm-info">
          <h2>{t('รายชื่อวิชาที่ต้องการขอทบทวนผลคะแนนสอบ')}</h2>
          <div className="list">
            <div className="sub">
              <h3>
                <b>
                  {_.get(profile, 'title')}
                  {_.get(profile, 'first_name')} {_.get(profile, 'last_name')}
                </b>
              </h3>
            </div>
            <div className="_space"></div>
            <div className="t-box -recheck">
              <h2 className="s-flex -center color-orange">
                <i className="i-recheck"></i> {title}
              </h2>
              1. {t('อัตราค่าธรรมเนียมการขอทบทวนผลคะแนน')}
              <br />
              <div className="s-flex">
                <b>1.1</b>
                <b>{t('รายวิชา วิชาละ 100 บาท')}</b>
              </div>
              <div className="s-flex">
                <b>1.2</b>
                <span>
                  <b>{t('จ่ายค่าธรรมเนียม 300 บาท')}</b>
                  <br />
                  {t('สามารถขอทบทวนผลคะแนนได้ทุกรายวิชาที่เข้าสอบ')}
                </span>
              </div>
              2. {t('ผู้สมัครจะเรียกดูข้อมูลการทบทวนผลคะแนนได้ก็ต่อเมื่อสถานะการชำระเงินเสร็จสมบูรณ์แล้วเท่านั้น')}
              <b className="color-orange">{t('และไม่สามารถขอเงินคืนได้ในทุกกรณี')}</b>
            </div>
            <div className="recheck-form">
              <h3>
                1. {t('เลือกสาขาวิชาที่ท่านต้องการ')}
                {title}
              </h3>
              {Boolean(paidCount) && (
                <p className="s-flex -gap">
                  <i className="i-chosen"></i>
                  {t(' ท่านทำการเลือกและชำระเงินเรียบร้อยแล้ว')} {paidCount} {t('วิชา')}
                </p>
              )}
              <p>
                <label>
                  <input
                    type="checkbox"
                    checked={(selectedAppIds.length + paidCount) === applications.length}
                    onChange={() =>
                      setSelectedAppIds(
                        (selectedAppIds.length + paidCount) === applications.length
                          ? []
                          : applications.filter((app) => !app.recheckable).map((app) => app._id)
                      )
                    }
                  />
                  <span>{t('เลือกทุกรายวิชา')}</span>
                </label>
              </p>
              {(applications || [])
              .map((app, index) => {
                const subject = subjectMap[app.subject_name] || {};
                return (
                  <div key={app._id} className="xm-box">
                    <div className="num">
                      <b>{index + 1}</b>
                    </div>
                    <ul>
                      <li>
                        {t('ชื่อวิชา')}{' '}
                        <b>
                          {subject.subjectKey} {subject.subjectName}
                        </b>
                      </li>
                      <li>
                        {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                      </li>
                    </ul>
                    {app.recheckable ? (
                      <i className="i-chosen"></i>
                    ) : (
                      <input
                        type="checkbox"
                        disabled={app.recheckable}
                        checked={selectedAppIds.includes(app._id)}
                        onChange={
                          app.recheckable ? () => {} : () => onSubjectToggle(app._id, selectedAppIds.includes(app._id))
                        }
                      />
                    )}
                  </div>
                );
              })}
              <h3>2. {t('สรุปค่าธรรมเนียม')}</h3>
              <div className="xm-box -total">
                <dl>
                  <dt>
                    {t('ขอทบทวนผลคะแนนสอบ')}
                    <ul>
                      {selectedAppIds.map((appId) => {
                        const subject = subjectMap[_.find(applications, (app) => app._id === appId).subject_name];
                        return (
                          <li key={appId}>
                            {subject.subjectKey} {subject.subjectName}
                          </li>
                        );
                      })}
                    </ul>
                  </dt>
                  <dd>
                    <b>{selectedAppIds.length}</b> {t('วิชา')}
                  </dd>
                </dl>
                <dl className="total">
                  <dt>{t('ยอดเงินที่ต้องชำระ')}</dt>
                  <dd>
                    <b>{paidAmount}</b> {t('บาท')}
                  </dd>
                </dl>
              </div>
              <h3>3. {t('ชำระเงิน')}</h3>
              <div className="note">
                {t(
                  'สามารถชำระเงินผ่าน QR Code ทุกธนาคาร (ฟรี ไม่มีค่าธรรมเนียม) หรือชำระเงินผ่านเคาน์เตอร์เซอร์วิส (เซเว่น อีเลฟเว่น - ค่าธรรมเนียม 10 บาทต่อรายการ) ได้'
                )}
                <p>
                  <img alt="banks" src="/assets/img/logo/banks.png" width="328" />
                  <img alt="counter service" src="/assets/img/logo/cs-7-11.png" width="95" />
                </p>
                <p>
                  {t(
                    `เมื่อคลิกปุ่มยืนยัน ระบบจะสร้างใบแจ้งชำระเงินในรูปแบบ PDF ให้ท่านพิมพ์หรือจับภาพหน้าจอเพื่อนำไปชำระเงิน โดยให้ชำระเงินภายในเวลา 23.29 น. ของวันที่ ${CBT ? '31 ธ.ค. 67' : type === 'alevel' ? '25 เม.ย. 68' : '15 ม.ค. 68'} `
                  )}{' '}
                </p>
                {t(
                  `โดยสามารถตรวจสอบกระดาษคำตอบและยื่นคำร้องขอทบทวนผลคะแนนสอบได้ถึงเวลา 23.59 น. ของวันที่ ${CBT ? '31 ธ.ค. 67' : type === 'alevel' ? '25 เม.ย. 68' : '15 ม.ค. 68'}  เท่านั้น`
                )}
                <small>
                  {t('หมายเหตุ: การขอทบทวนผลคะแนนสอบนี้จะสมบูรณ์ เมื่อท่านกดยืนยันและชำระเงินในระบบครบถ้วนแล้ว')}
                </small>
              </div>
            </div>
            <div className="action">
              <a
                className={cn({
                  'btn-main': true,
                  'cursor-pointer': !disabled,
                  disabled,
                  loading: submitting,
                })}
                onClick={disabled ? () => {} : onSubmit}
              >
                {t('ยืนยันและชำระเงิน')} {paidAmount} {t('บาท')}
              </a>
            </div>
          </div>
        </div>
        <h4 className="text-center">
          <Link to="/profile">{t('กลับสู่หน้าข้อมูลการสอบ')} →</Link>
        </h4>
      </div>
    </main>
  );
};

export default ExamRecheck;
