/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { callAPI } from 'helpers';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Data
import { TGATTPATINFOS, ALEVELINFOS } from 'data/exam-days';
import {
  // cbt_tgattpat_recheck,
  pbt_tgattpat_recheck,
  // pbt_alevel_recheck
} from 'data/enabled';

const ExamScores = ({ type = 'tgattpat' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const subjectInfos = type === 'tgattpat' ? TGATTPATINFOS : ALEVELINFOS;
  const subjectMap = subjectInfos.reduce((_map, { date, dayIndex, examSlots }) => {
    return _.merge(
      _map,
      _.keyBy(
        examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
        'subjectKey'
      )
    );
  }, {}); 

  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [recheckable, setRecheckable] = useState(false);
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, timestamp } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type }
        });

        // if (applications.every(app => app.selected_exam_type === 'pbt')) {
        //   navigate('/profile');
        // }

        setApplications(
          applications
            .slice()
        );
        setTimestamp(timestamp);
        // const availableTime = moment(timestamp).isBetween(cbt_tgattpat_recheck.start, cbt_tgattpat_recheck.end);
        const availableTime = moment(timestamp).isBetween(pbt_tgattpat_recheck.start, pbt_tgattpat_recheck.end);

        // const availableTime = type === 'tgattpat'
        //   ? moment(timestamp).isBetween(pbt_tgattpat_recheck.start, pbt_tgattpat_recheck.end)
        //   : moment(timestamp).isBetween(pbt_alevel_recheck.start, pbt_alevel_recheck.end);
        setRecheckable(applications.some((app) => app.recheckable) && availableTime);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    fetchApplications();
  }, [navigate, type]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const [opening, setOpening] = useState(false);
  const onPdfOpen = async () => {
    if (opening || !profile.picture) return;
    try {
      setOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: type === 'tgattpat'
          ? '/exam-applications/tgattpat-score-pdf'
          : '/exam-applications/alevel-score-pdf'
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setTimeout(() => setOpening(false), 1000);
    } catch (error) {
      console.error(`Error while trying to download score pdf: ${error.message}`);
      setOpening(false);
    }
  };

  // const availableTime = moment(timestamp).isBetween(cbt_tgattpat_recheck.payment_start, cbt_tgattpat_recheck.payment_end);
  const availableTime = moment(timestamp).isBetween(pbt_tgattpat_recheck.start, pbt_tgattpat_recheck.end);

  // const availableTime = type === 'tgattpat'
  //   ? moment(timestamp).isBetween(pbt_tgattpat_recheck.payment_start, pbt_tgattpat_recheck.payment_end)
  //   : moment(timestamp).isBetween(pbt_alevel_recheck.payment_start, pbt_alevel_recheck.payment_end);
  const recheckEnabled =
    applications.some((app) => !(app.is_absent || app.is_dishonest || app.is_empty_sheet || app.is_not_has_testset)) &&
    // !applications.some((app) => app.set === 3) &&
    Boolean(applications.filter((app) => !app.recheckable).length) &&
    availableTime;
  if (loading || !timestamp) return null;

  return (
    <main className="site-body">
      {loading && <div className="t-loading" />}
      <div className="t-form">
        <h1>{type === 'alevel' ? t('ประกาศผลสอบ A-Level') : t('ประกาศผลสอบ TGAT/TPAT2-5')}</h1>
        <div className="xm-info">
          <h2>{t('รายละเอียดผลสอบ')}</h2>
          <div className="list">
            <div className="sub">
              <h3>
                {t('ผลสอบจำนวน')}{' '}
                <b>
                  {(applications || []).length} {t('วิชา')}
                </b>
              </h3>
              <p className="action _space">
                <a
                  className={cn({ 'btn-main': true, 'cursor-pointer': !opening, disabled: opening, loading: opening })}
                  onClick={onPdfOpen}
                >
                  <i className="i-pdf"></i>
                  {t('หนังสือรับรองผลการสอบแยกรายวิชา')}
                </a>
              </p>
            </div>
            {(applications || [])
            .sort((a, b) => {
              return type === 'tgattpat'
              ? a.subject_name.localeCompare(b.subject_name)
              : subjectMap[a.subject_name].subjectCode.localeCompare(subjectMap[b.subject_name].subjectCode)
            })
            .map((app, index) => {
              const subject = subjectMap[app.subject_name];
              const score = app.score;
              const is_abnormal = app.is_absent || app.is_dishonest || app.is_empty_sheet || app.is_not_has_testset || app.is_not_announced;
              const announcable = true; 
              // const announcable = app.selected_exam_type === 'cbt';

              if (['TGAT', 'TPAT2'].includes(app.subject_name)) {
                const scoreString = app.score;
                const mainScore = parseFloat(scoreString || '0');
                const hasScore = Boolean(!is_abnormal || (is_abnormal && mainScore));
                return (
                  <div key={app._id} className="xm-box">
                    <div className="num">
                      <b>{index + 1}</b>
                    </div>
                    <ul>
                      <li>
                        {t('ชื่อวิชา')}{' '}
                        <b>
                          {subject.subjectKey} {subject.subjectName}
                        </b>
                      </li>
                      <li>
                        {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                      </li>
                      <li className="score">
                        <dl>
                          <dt>{t('คะแนนที่ได้ / คะแนนเต็ม')}</dt>
                          {!announcable ? (
                            <dd className="text-center">
                              <strong>{t('รอประกาศผล')}</strong>
                            </dd>
                          ) : hasScore ? (
                            <dd>
                              <b>{scoreString}</b> / {subject.maxValue}
                            </dd>
                          ) : (
                            <dd className="text-center">
                              <strong>
                                {app.is_absent
                                  ? t('ขาดสอบ')
                                  : (app.is_dishonest || app.is_not_announced)
                                  ? t('ไม่ประกาศผล')
                                  : app.is_empty_sheet
                                  ? t('ไม่ฝนคำตอบ')
                                  : app.is_not_has_testset
                                  ? 'ไม่ฝนเลขชุด'
                                  : ''}
                              </strong>
                            </dd>
                          )}
                        </dl>
                        {(hasScore && announcable) && (
                          <>
                            {subject.subSubjects.map(({ subjectCode, subjectKey, subjectName, maxValue }) => {
                              const score = app[`score_${subjectCode.slice(-1)}`];
                              // profile[subjectKey.toLowerCase()];
                              const noScore = !parseFloat(score) && mainScore;
                              return (
                                <dl key={subjectCode}>
                                  <dt>
                                    {subjectKey} {subjectName}
                                  </dt>
                                  {app.is_absent && noScore ? (
                                    <dd>
                                      <strong>{t('ขาดสอบ')}</strong>
                                    </dd>
                                  ) : app.is_empty_sheet && noScore ? (
                                    <dd>
                                      <strong>{t('ไม่ฝนคำตอบ')}</strong>
                                    </dd>
                                  ) : app.is_not_has_testset && noScore ? (
                                    <dd>
                                      <strong>{t('ไม่ฝนเลขชุด')}</strong>
                                    </dd>
                                  ) : app.is_not_announced && noScore ? (
                                    <dd>
                                      <strong>{t('ไม่ประกาศผล')}</strong>
                                    </dd>
                                  ) : (
                                    <dd>
                                      <b>{score}</b> / {maxValue}
                                    </dd>
                                  )}
                                </dl>
                              );
                            })}
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                );
              }

              return (
                <div key={app._id} className="xm-box">
                  <div className="num">
                    <b>{index + 1}</b>
                  </div>
                  <ul>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subject.subjectKey} {subject.subjectName}
                      </b>
                    </li>
                    <li>
                      {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                    </li>
                    <li className="score">
                      <dl>
                        <dt>{t('คะแนนที่ได้ / คะแนนเต็ม')}</dt>
                        {!announcable ? (
                          <dd className="text-center">
                            <strong>{t('รอประกาศผล')}</strong>
                          </dd>
                        ) : !is_abnormal ? (
                          <dd>
                            <b>{score}</b> / {subject.maxValue}
                          </dd>
                        ) : (
                          <dd className="text-center">
                            <strong>
                              {app.is_absent
                                ? t('ขาดสอบ')
                                : (app.is_dishonest || app.is_not_announced)
                                ? t('ไม่ประกาศผล')
                                : app.is_empty_sheet
                                ? t('ไม่ฝนคำตอบ')
                                : app.is_not_has_testset
                                ? 'ไม่ฝนเลขชุด'
                                : ''}
                            </strong>
                          </dd>
                        )}
                      </dl>
                    </li>
                  </ul>
                </div>
              );
            })}
            <div className="action">
              <p className="_space">
                <a
                  className={cn({ 'btn-main': true, 'cursor-pointer': !opening, disabled: opening, loading: opening })}
                  onClick={onPdfOpen}
                >
                  <i className="i-pdf"></i>
                  {t('หนังสือรับรองผลการสอบแยกรายวิชา')}
                </a>
              </p>
              {recheckable && (
                <p>
                  <Link to={`/profile/exam-answers/${type}`} className="btn-recheck -border">
                    <i className="i-recheck"></i>
                    {t('ดูคำตอบและคะแนนข้อสอบ')}
                  </Link>
                </p>
              )}
              {recheckEnabled && (
                <>
                  <p>
                    <small className="color-gray">{t('กรณีที่ผู้สมัครต้องการขอตรวจคำตอบและขอทบทวนผลคะแนนสอบ')}</small>
                  </p>
                  <Link to={`/profile/exam-recheck/${type}`} className="btn-recheck">
                    <i className="i-recheck"></i>
                    {t('ขอทบทวนผลคะแนนสอบ')}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <h4 className="text-center">
          <Link to="/profile">{t('กลับสู่หน้าข้อมูลการสอบ')} →</Link>
        </h4>
      </div>
    </main>
  );
};

export default ExamScores;
