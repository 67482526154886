import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { callAPI } from 'helpers';
import _ from 'lodash';
import cn from 'classnames';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

const ExamAnswerDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appId } = useParams();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(true);
  const [checked1, setChecked1] = useState(false);
  // const [checked2, setChecked2] = useState(false);
  // const [checked3, setChecked3] = useState(false);
  // const [checked4, setChecked4] = useState(false);
  // const [checked5, setChecked5] = useState(false);
  // const [checked6, setChecked6] = useState(false);
  // const [checked7, setChecked7] = useState(false);
  // const [checked8, setChecked8] = useState(false);

  useEffect(() => {
    const fetchAnswer = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({
          url: `/applicants/me/exam-recheckables/${appId}`,
        });
        if (!result) {
          navigate('/profile');
        }
        setAnswer(result);
        const subject =  result.subject;

        if (!['TGAT', 'TPAT2'].includes(subject.subjectKey)) {
          setQuestions(
            Array(subject.questionCount)
              .fill()
              .map((__, index) => [false, '', `question_${subject.subjectKey.toLowerCase()}_${index + 1}`])
          );
        } else {
          setQuestions(
            subject.subSubjects.reduce(
              (array, { questionCount, subjectKey }) =>
                array.concat(
                  Array(questionCount)
                    .fill()
                    .map((__, index) => [false, '', `question_${subjectKey.toLowerCase()}_${index + 1}`])
                ),
              []
            )
          );
        }

        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch answer: ${error.message}`);
        setLoading(false);
        navigate('/profile');
      }
    };
    fetchAnswer();
  }, [navigate, appId]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const [textWrongScore, setTextWrongScore] = useState('');
  const [questions, setQuestions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const subject = _.get(answer, 'subject');
  const data = (textWrongScore ? [{ key: 'wrong_score', text: textWrongScore }] : []).concat(
    questions.map(([active, text, key]) => ({ key, active, text })).filter(({ active }) => active)
  );

  const onSubmit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      await callAPI({
        method: 'POST',
        url: `/applicants/me/exam-recheckables/${appId}`,
        body: { data },
      });
      setAnswer((_answer) => ({ ..._answer, recheck_requests: true }));
      setSubmitting(false);
    } catch (error) {
      console.error(new Error(`Error while trying to update a password: ${error.message}`));
      setSubmitting(false);
    }
  };

  // const allChecked = checked1 && checked2 && checked3 && checked4 && checked5 && checked6 && checked7 && checked8;
  const allChecked = checked1;
  const disabled = !data.length;

  if (loading || !subject) return null;
  return (
    <main className="site-body">
      {loading && <div className="t-loading" />}
      <h1>{t('ดูคำตอบและคะแนนข้อสอบ')}</h1>
      <div className="xm-info xm-answer">
        <h2>
          {profile.title}
          {profile.first_name} {profile.last_name}
        </h2>
        <div className="list">
          <div className="xm-box">
            <div className="num">
              <b>1</b>
            </div>
            <ul>
              <li>
                {t('ชื่อวิชา')}{' '}
                <b>
                  {subject.subjectKey} {subject.subjectName}
                </b>
              </li>
              <li>
                {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
              </li>
              <li className="flex items-center gap-1">
                {t('ประเภทข้อสอบ')}{' '}
                <b className="flex items-center gap-1">
                  {answer.selected_exam_type === 'cbt' ? (
                    <>
                      <i className="i-computer" />
                      {t('คอมพิวเตอร์')}
                    </>
                  ) : (
                    <>
                      <i className="i-paper" />
                      {t('กระดาษ')}
                    </>
                  )}
                </b>
              </li>
              <li className="score">
                <dl>
                  <dt>
                    {t('คะแนนที่ได้')} /{t('คะแนนเต็ม')}
                  </dt>
                  <dd>
                    <b>{answer.score}</b> / {subject.maxValue}
                  </dd>
                </dl>
                {['TGAT', 'TPAT2'].includes(subject.subjectKey) && (
                  <>
                    {(subject.subSubjects || []).map(({ subjectKey, subjectName, subjectCode }) => (
                      <dl key={subjectKey}>
                        <dt>
                          ■ {subjectKey} {subjectName}
                        </dt>
                        <dd>
                          <b>{answer[`score_${subjectCode.slice(-1)}`]}</b> / {subject.maxValue}
                        </dd>
                      </dl>
                    ))}
                  </>
                )}
                {answer.is_empty_sheet && (
                  <dl>
                    <dt>
                      <b className="color-orange">
                        {t('หมายเหตุ:')} {t('ไม่ฝนคำตอบ')}
                      </b>
                    </dt>
                  </dl>
                )}
                {answer.is_not_has_testset && (
                  <dl>
                    <dt>
                      <b className="color-orange">
                        {t('หมายเหตุ:')} {t('ไม่ฝนเลขชุด')}
                      </b>
                    </dt>
                  </dl>
                )}
              </li>
            </ul>
          </div>
          {answer.selected_exam_type === 'pbt' && (
            <>
              {['TGAT', 'TPAT2', 'TPAT4', 'Math1', 'Math2', 'Phy', 'Chem'].includes(subject.subjectKey) ? (
                <>
                  <div className="xm-sheets">
                    <div className="sheet">
                      <h3>
                        {t('กระดาษคำตอบของท่านวิชา')} {subject.subjectKey} (ด้านหน้า)
                      </h3>
                      <img alt="Student Sheet" src={answer.sheet_path_1} />
                    </div>
                    {answer.is_empty_sheet || answer.is_not_has_testset ? null : (
                      <div className="sheet">
                        <h3>
                          {t('เฉลยข้อสอบวิชา')} {subject.subjectKey} (ด้านหน้า)
                        </h3>
                        <img alt="Answer Sheet" src={answer.key_path_1} />
                      </div>
                    )}
                  </div>
                  <div className="xm-sheets">
                    <div className="sheet">
                      <h3>
                        {t('กระดาษคำตอบของท่านวิชา')} {subject.subjectKey} (ด้านหลัง)
                      </h3>
                      <img alt="Student Sheet" src={answer.sheet_path_2} />
                    </div>
                    {answer.is_empty_sheet || answer.is_not_has_testset ? null : (
                      <div className="sheet">
                        <h3>
                          {t('เฉลยข้อสอบวิชา')} {subject.subjectKey} (ด้านหลัง)
                        </h3>
                        <img alt="Answer Sheet" src={answer.key_path_2} />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="xm-sheets">
                  <div className="sheet">
                    <h3>
                      {t('กระดาษคำตอบของท่านวิชา')} {subject.subjectKey}
                    </h3>
                    <img alt="Student Sheet" src={answer.sheet_path_1} />
                  </div>
                  {answer.is_empty_sheet || answer.is_not_has_testset ? null : (
                    <div className="sheet">
                      <h3>
                        {t('เฉลยข้อสอบวิชา')} {subject.subjectKey}
                      </h3>
                      <img alt="Answer Sheet" src={answer.key_path_1} />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <SectionAnswerTables subject={subject} answer={answer} />
          {answer.is_empty_sheet || answer.is_not_has_testset ? null : answer.recheck_requests ? (
            <h4 className="text-center">{t('ได้รับคำร้องขอทบทวนคะแนนเรียบร้อยแล้ว')}</h4>
          ) : (
            <details>
              <summary>{t('พบข้อผิดพลาดในการตรวจให้คะแนน')}?</summary>
              <div className="t-panel">
                <div className="t-form">
                  <p>
                    {t(
                      'หากพบข้อผิดพลาดในการตรวจให้คะแนน ผู้เข้าสอบสามารถดําเนินการยื่นคําร้องขอทบทวนคะแนนได้ตามแบบฟอร์มด้านล่างนี้ โดยสามารถ '
                    )}
                    <b>
                      <span className="color-orange">
                        <u>{t('ส่งได้ครั้งเดียว')}</u>
                      </span>{' '}
                      {['4','5','6'].includes(answer.day)
                        ? t('ภายในวันที่ 25 เมษายน 2568 เวลา 23.59 น.')
                        : t('ภายในวันที่ 15 มกราคม 2568 เวลา 23.59 น.')}
                    </b>{' '}
                    {t('หากไม่ยื่นคำร้องในเวลาที่กำหนด ถือว่ายอมรับคะแนนโดยปริยาย')}
                  </p>
                  <p>
                    {t('กรุณาอ่านและ')} <b>{t('คลิกยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไข')} {t('ก่อนกรอกแบบฟอร์ม')}
                  </p>
                  <ul className="r-consent">
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          required
                          checked={checked1}
                          onChange={() => setChecked1((checked) => !checked)}
                        />
                        <span>
                          {t(
                            'การแสดงผลคำตอบการสอบแบบคอมพิวเตอร์ (CBT) เป็นไปตามลำดับของต้นฉบับข้อสอบทั้งข้อและตัวเลือก มิได้แสดงตามปรากฎที่หน้าจอคอมพิวเตอร์ขณะทดสอบ'
                          )}
                          &nbsp;{t('ส่วนการสอบแบบกระดาษ (PBT) จะแสดงตามชุดข้อสอบ')}
                        </span>
                      </label>
                    </li>
                  </ul>
                  {allChecked && (
                    <>
                      <h2>{t('เลือกข้อที่ต้องการขอทบทวนคะแนน')}</h2>
                      <p>
                        1. {t('กรณีพบคำนวณคะแนนผิด ให้กรอกรายละเอียดอธิบาย')} <br />
                        <input
                          type="text"
                          placeholder="กรอกรายละเอียด"
                          className="full"
                          value={textWrongScore}
                          onChange={(e) => setTextWrongScore(e.target.value)}
                        />
                      </p>
                      <p>2. {t('กรณีพบข้อที่เฉลยผิด ให้เลือกข้อนั้นๆ และกรอกรายละเอียดอธิบาย')}</p>
                      {['TGAT', 'TPAT2'].includes(subject.subjectKey) ? (
                        <>
                          {(subject.subSubjects || []).map(
                            ({ subjectKey, subjectName, questionCount }, subjectIndex, all) => (
                              <>
                                <p>
                                  2.{subjectIndex + 1} {subjectKey} {subjectName}
                                </p>
                                <ul className="xm-choices">
                                  {Array(questionCount)
                                    .fill()
                                    .map((__, _index) => {
                                      const index = !subjectIndex
                                        ? _index
                                        : _index + _.sumBy(all.slice(0, subjectIndex), 'questionCount');
                                      return (
                                        <li key={index} className={cn({ active: questions[index][0] })}>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={questions[index][0]}
                                              onChange={() =>
                                                setQuestions((questions) =>
                                                  questions.map((question, i) =>
                                                    i === index ? [!question[0], question[1], question[2]] : question
                                                  )
                                                )
                                              }
                                            />
                                            {_index + 1}
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="กรอกรายละเอียด"
                                            value={questions[index][1]}
                                            onChange={(e) =>
                                              setQuestions((questions) =>
                                                questions.map((question, i) =>
                                                  i === index ? [question[0], e.target.value, question[2]] : question
                                                )
                                              )
                                            }
                                          />
                                        </li>
                                      );
                                    })}
                                </ul>
                              </>
                            )
                          )}
                        </>
                      ) : (
                        <ul className="xm-choices">
                          {Array(subject.questionCount)
                            .fill()
                            .map((_, index) => (
                              <li key={index} className={cn({ active: questions[index][0] })}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={questions[index][0]}
                                    onChange={() =>
                                      setQuestions((questions) =>
                                        questions.map((question, i) =>
                                          i === index ? [!question[0], question[1], question[2]] : question
                                        )
                                      )
                                    }
                                  />
                                  {index + 1}
                                </label>
                                <input
                                  type="text"
                                  placeholder="กรอกรายละเอียด"
                                  value={questions[index][1]}
                                  onChange={(e) =>
                                    setQuestions((questions) =>
                                      questions.map((question, i) =>
                                        i === index ? [question[0], e.target.value, question[2]] : question
                                      )
                                    )
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      )}
                      <div className="t-box -info -noti">
                        <b className="color-orange">
                          {t('สามารถยื่นคำร้องขอทบทวนคะแนนได้เพียงครั้งเดียว และไม่สามารถขอแก้ไขภายหลังได้')}
                        </b>
                      </div>
                      <div className="action">
                        <button
                          className={cn('btn-main -danger', { disabled: disabled, 'cursor-pointer': !disabled })}
                          onClick={disabled ? () => {} : onSubmit}
                        >
                          {t('ยื่นคำร้องขอทบทวนคะแนน')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </details>
          )}
        </div>
      </div>
      <h4 className="text-center">
        <Link to="/profile">{t('กลับสู่หน้าข้อมูลการสอบ')} →</Link>
      </h4>
    </main>
  );
};

export default ExamAnswerDetail;

const SectionAnswerTables = ({ subject, answer }) => {
  const { t } = useTranslation();
  return (
    <div className="xm-cbt">
      <h3 className="text-center">
        {t('คำตอบของท่านวิชา')} {subject.subjectKey} {subject.subjectName}
      </h3>
      {(subject.subSubjects || []).length ? (
        <>
          {(subject.subSubjects || []).map(({ subjectKey, subjectName, questionCount }, subjectIndex, all) => {
            const initial = subjectIndex ? _.sumBy(all.slice(0, subjectIndex), 'questionCount') : 0;
            return (
              <>
                <h4 className="text-center">
                  {subjectKey} {subjectName}
                </h4>
                <AnswerTable answers={answer.answer_array.slice(initial, initial + questionCount)} />
              </>
            );
          })}
        </>
      ) : (
        <AnswerTable answers={answer.answer_array} />
      )}
    </div>
  );
};

const AnswerTable = ({ answers }) => {
  const questionCount = answers.length;
  return (
    <>
      <div className="cbt-ans">
        <table>
          <thead>
            <tr>
              <th>ข้อ</th>
              <th>ตอบ</th>
              <th>เฉลย</th>
              <th>คะแนน</th>
              <th>คะแนนสะสม</th>
            </tr>
          </thead>
          <tbody>
            {Array(Math.floor(questionCount / 2))
              .fill()
              .map((__, index) => {
                const scoreString = answers[index].score;
                const formattedScore = _.trimEnd(_.trimEnd(scoreString, '0'), '.') || '0';
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{answers[index].answer}</td>
                    <td>{answers[index].key}</td>
                    <td style={{ color: formattedScore === '0' ? 'red' : 'green' }}>{formattedScore}</td>
                    <td>{answers[index].accu_score.toFixed(6)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>ข้อ</th>
              <th>ตอบ</th>
              <th>เฉลย</th>
              <th>คะแนน</th>
              <th>คะแนนสะสม</th>
            </tr>
          </thead>
          <tbody>
            {Array(Math.ceil(questionCount / 2))
              .fill()
              .map((__, index) => {
                const _index = Math.floor(questionCount / 2) + index;
                const scoreString = answers[_index].score;
                const formattedScore = _.trimEnd(_.trimEnd(scoreString, '0'), '.') || '0';

                if (answers[_index].question === 'special') {
                  return (
                    <tr key={_index}>
                      <td colSpan={3}>คะแนนชดเชยพิเศษ</td>
                      <td style={{ color: formattedScore === '0' ? 'red' : 'green' }}>{formattedScore}</td>
                      <td>{answers[_index].accu_score.toFixed(6)}</td>
                    </tr>
                  )
                }

                return (
                  <tr key={_index}>
                    <td>{_index + 1}.</td>
                    <td>{answers[_index].answer}</td>
                    <td>{answers[_index].key}</td>
                    <td style={{ color: formattedScore === '0' ? 'red' : 'green' }}>{formattedScore}</td>
                    <td>{answers[_index].accu_score.toFixed(6)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
