/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';

import menus from 'nav/menus';
import Logo from 'nav/logo';

const TGATRedemptionReceipt = () => {
  const { hash } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [receipt, setReceipt] = useState();
  useEffect(() => {
    const fetchReceipt = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({
          method: 'POST',
          url: '/external/tgat-receipt',
          body: { hash }
        });
        setReceipt(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log({ error });
        navigate('/');
      }
    }

    fetchReceipt();
  }, [hash, navigate]);
  
  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);
  
  return (
    <div id="tcas">
      <div className="t-loading"></div>
      <header className="site-header">
        <nav className="site-nav">
          <ul>
            {menus.map(({ key, icon, title, to, href, desktop }) => (
              <li key={key} className={cn(`m-${key}`, { _desktop: desktop, active: key === 'register' })}>
                {to && (
                  <Link to={to}>
                    {icon}
                    <span>{title}</span>
                  </Link>
                )}
                {href && (
                  <a href={href}>
                    {icon}
                    <span>{title}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Logo />
      <main className="site-body">
        {loading && <div className='t-loading' />}
        <div className='t-form'>
          <h1>ใบเสร็จรับเงิน TCAS68 ค่าสมัครสอบวิชา TGAT ความถนัดทั่วไป</h1>
          <div className='xm-info'>
            <h2>ใบเสร็จรับเงิน</h2>
            <div className='list' style={{ background: 'white', fontSize: 14 }}>
              <div>
                <span>ชื่อ-นามสกุล&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'full_name')}</b>
              </div>
              <div>
                <span>รหัสประจำตัว&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'citizen_id')}</b>
              </div>
              <div>
                <span>เลขที่เอกสาร&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'summary.document_id')}</b>
              </div>
              <div>
                <span>จำนวนเงิน&nbsp;&nbsp;&nbsp;</span>
                <b>140.00 บาท (หนึ่งร้อยสี่สิบบาทถ้วน)</b>
              </div>
              <div style={{ marginTop: 20 }} className='color-red'>
                ผู้สมัครยินยอมรับเงินสนับสนุนค่าสมัครสอบวิชา TGAT ความถนัดทั่วไป จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม และยินยอมมอบสิทธิ์ให้สมาคมที่ประชุมอธิการบดีแห่งประเทศไทยดำเนินการแทนผู้สมัครในการเบิกเงินสนับสนุนค่าสมัคร
              </div>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5' }}>
              <span>ผู้จ่ายเงิน&nbsp;&nbsp;&nbsp;</span>
              <b>สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม</b>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5' }}>
              <span>ผู้รับเงินแทน&nbsp;&nbsp;&nbsp;</span>
              <b>สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย</b>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5', borderRadius: '0 0 5px 5px' }}>
              <span>จำนวนเงินรวมทั้งสิ้น&nbsp;&nbsp;&nbsp;</span>
              <b style={{ fontSize: 18 }}>140.00 บาท</b>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- END BODY --> */}
    </div>
  );
}

export default TGATRedemptionReceipt;